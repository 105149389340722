import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import * as CryptoJS from 'crypto-js';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class CommonService {
  public userData = new BehaviorSubject<any>({});
  public oktaDetail: any;
  toastrRef: any;
  public isWarningOpen = new BehaviorSubject<boolean>(false);
  public orgFileDate = new BehaviorSubject<boolean>(false);

  constructor(public _router: Router, private toastController: ToastController, private toastr: ToastrService) { }

  async isNetworkError(show: boolean) {
    const timelessConfig: Partial<any> = {
      timeOut: 0, // Set to 0 for timeless toastr
      extendedTimeOut: 0,
      disableTimeOut: true,
      closeButton: true,
      tapToDismiss: false,
      positionClass: 'toast-bottom-center'
    };
    if (show) {
      this.toastrRef = this.toastr.error('A network error occurred. Please check your internet connection!', '', timelessConfig)
    }
    else {
      this.toastr.clear(this.toastrRef.ToastId);
    }
  }

  async isDownloading(show: boolean) {
    const timelessConfig: Partial<any> = {
      timeOut: 2000,
      closeButton: false,
      tapToDismiss: false,
      positionClass: 'toast-bottom-right'
    };
    if (show) {
      this.toastrRef = this.toastr.info('Report Downloading in process!', '', timelessConfig);
    }
    else {
      this.toastrRef = this.toastr.success('Report Downloaded!', '', timelessConfig);
    }
  }

  async isSsoError(show: boolean) {
    const timelessConfig: Partial<any> = {
      timeOut: 0, // Set to 0 for timeless toastr
      extendedTimeOut: 0,
      disableTimeOut: true,
      closeButton: true,
      tapToDismiss: false,
      positionClass: 'toast-bottom-center'
    };
    if (show) {
      this.toastrRef = this.toastr.error('No organizations are associated with this user.', '', timelessConfig)
    }
    else {
      this.toastr.clear(this.toastrRef.ToastId);
    }
  }

  public getOktaDetails() {
    return this.oktaDetail;
  }

  public setOktaDetails(Input: any) {
    this.oktaDetail = Input;
  }

  public setSessionStorage(key: any, value: any) {
    if (value === "null") {
      return sessionStorage.setItem(key, (value))
    } else if (value === "") {
      return sessionStorage.setItem(key, (value))
    } else if (value === "[]") {
      return sessionStorage.setItem(key, (value))
    } else {
      return sessionStorage.setItem(key, this.encryptData(value))
    }
  }

  public getSessionStorage(key: any) {
    if (sessionStorage.getItem(key)) {
      var value = sessionStorage.getItem(key)
      if (value === "null") {
        return value
      } else if (value === "") {
        return value
      } else if (value === "[]") {
        return value
      } else {
        return this.decryptData(sessionStorage.getItem(key));
      }
    }
    else {
      return ""
    }
  }

  public setLocalStorage(key: any, value: any) {
    const encrkey = this.encryptData(value);
    // Check if the data is null
    if (value === "null") {
      return localStorage.setItem(key, (value))
    } else if (value === "") {
      return localStorage.setItem(key, (value))
    } else if (value === "[]") {
      return localStorage.setItem(key, (value))
    } else {
      return localStorage.setItem(key, encrkey)
    }

  }

  public getLocalStorage(key: any) {
    // const encrkey = this.encryptData(key);
    if (localStorage.getItem(key)) {
      var value = localStorage.getItem(key)
      if (value === "null") {
        return value
      } else if (value === "") {
        return value
      } else if (value === "[]") {
        return value
      } else {
        return this.decryptData(localStorage.getItem(key));
      }
    }
    else {
      return ""
    }
  }

  encryptData(data: any): string {
    // Ensure data is not null or undefined
    if (data == null) {
      throw new Error('Data cannot be null or undefined');
    }

    // Convert object to JSON string
    const dataString = JSON.stringify(data);

    // Ensure the secret key is available
    if (!environment.secretKey) {
      throw new Error('Secret key is missing or invalid');
    }

    const encryptedToken = CryptoJS.AES.encrypt(dataString, environment.secretKey);
    return encryptedToken.toString();
  }

  decryptData(encryptedData: any): any {
    try {
      const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, environment.secretKey);
      const decryptedString = decryptedBytes.toString(CryptoJS.enc.Utf8);
      if (decryptedString) {
        // Parse the decrypted string into an object
        return JSON.parse(decryptedString);
      } else {
        // Handle the case where decryptedString is null or undefined
        console.error('Decrypted data is null or undefined');
        return null; // Or handle it according to your requirements
      }
    } catch (error: any) {
      //console.log("Error ",error.message)
      if (error.message.includes("Malformed UTF-8 data")) {
        this.removeAllStorage()
        this._router.navigate(['login']).then(() => { window.location.reload(); });
      }
    }
  }

  removeAllStorage() {
    localStorage.clear()
    sessionStorage.clear()
  }

  public removeLocalStorage(key: any) {
    return localStorage.removeItem(key)
  }
}
