import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent  implements OnInit {

  constructor(private bsModalRef: BsModalRef) { }

  ngOnInit() {}
  onClose() {
    this.bsModalRef.hide();
  }
}
