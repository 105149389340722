import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './alt-views/login/login.component';
import { AuthGuard } from './core/services/auth.guard';
import { LogoutComponent } from './main/components/session-mgnt-component/logout/logout.component';
import { AvailityComponent } from './sso/availity/availity.component';
import { OktaComponent } from './sso/okta/okta.component';
import { ReportDownloadComponent } from './main/pages/organizations/report-download/report-download.component';


const routes: Routes = [
  // {
  //   path: 'home',
  //   loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  // },
  {
    path: '',
    redirectTo: 'sso/okta',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'sso/okta',
    component: OktaComponent,
  },
  {
    path: 'sso/availity',
    component: AvailityComponent,
  },
  {
    path: 'logout',
    component: LogoutComponent,
  },
  {
    path: 'reportDownload',
    component: ReportDownloadComponent,
  },
  {
    path: 'app',
    canActivate:[AuthGuard],
    loadChildren: () =>
      import('./main/main.module').then((m) => m.MainModule)
  },
  {
    path: '**',
    redirectTo: 'app',
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule],
  providers:[AuthGuard]
})
export class AppRoutingModule { }
