<div class="report_wrapper borderBottom40" [ngClass]="isDesktop == false ? 'mobileMenu' : '' ">
  <div class="bg-white header-wrapper">
    <div class="logo-wrapper pr-5" slot="start">
      <ion-img class="logo-img" src="../../../../assets/images/Value-Partnerships-Logo.png"></ion-img>
    </div>
  </div>
</div>
<div class="pt-7 report_info">
  <div class="column">
    <ion-text color="gray100" class="font-header fw-500 mb-3">{{this.inputData.report}}</ion-text>
  </div>
  <ion-text class="font-md" color="gray80">Requested at {{apiRequestDateTime | date:'MMM d, y h:mm a'}}</ion-text>
  <div class="mt-2">
    <div class="column" *ngIf="downloadComplete">
      <ion-text class="font-md mb-2" color="gray80">Completed at {{apiCompletionDateTime | date:'MMM d, y h:mm a'}}</ion-text>
      <ion-text class="font-md mb-3" color="gray80">
        Your report is ready to download!
      </ion-text>
      <ion-text *ngIf="this.inputData.report === 'Excel Report'" class="font-md pl-1" color="highlight">
        <span class="pointer" (click)="excelReportDownload()"> Download Report </span>
      </ion-text>
      <ion-text *ngIf="this.inputData.report === 'Required Capabilities'" class="font-md pl-1" color="highlight" >
        <span class="pointer" (click)="requiredReportDownload()">Download Report</span>
      </ion-text>
    </div>
  </div>

  <div class="download-report-note" *ngIf="!downloadComplete">
    <div class="column">
      <ion-text class="font-d report_note" color="white">Your report is being generated. The page will refresh automatically when the report has finished generating, or, you can check back later.</ion-text>
        <div class="progress-bar">
          <div class="progress-bar-fill"></div>
        </div>
    </div>
  </div>
</div>
