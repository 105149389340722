import { Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { CommonService } from './common.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private commonService: CommonService, private _router: Router) { }

  doLogin(value: { username: string; password: string; }) {
    return new Promise<any>((resolve, reject) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(value.username, value.password)
        .then(
          res => {
            this.commonService.setLocalStorage('user', res?.user?.toJSON());
            resolve(res?.user?.getIdToken());
          },
          err => reject(err)
        );
    });
  }

  // logout() {
  //   firebase.auth().signOut().then(() => {
  //     this.commonService.removeAllStorage()
  //     this._router.navigate(['/okta']);
  //   });
  // }
}
