import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  getUserExist(email: any, poid: any) {
    return this.http.get<any>(environment.host + "pact/user/getuserbyemailandpoid?email=" + email + "&pobusinnessid=" + poid);
  }
}
