import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate,Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { CommonService } from 'src/app/shared/services/common.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, public commonService: CommonService) { } 
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      var isAuthenticated = this.commonService.getLocalStorage('isauthenticated'); 
      if (isAuthenticated=='true') { 
          // this.router.navigate(['/app/home']); 
          return true;
      } 
      else{
        this.router.navigate(['/login']); 
        return false; 
      }
  }
  
}
