import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { SAMLAuthProvider, User, getAuth } from "firebase/auth";
import { Router } from '@angular/router';
import { CommonService } from 'src/app/shared/services/common.service';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { environment } from 'src/environments/environment';
import { AuditService } from 'src/app/shared/services/audit.service';

const app = firebase.initializeApp(environment.firebaseConfig);
const auth = getAuth(app);

@Component({
  selector: 'app-okta',
  templateUrl: './okta.component.html',
  styleUrls: ['./okta.component.scss'],
})
export class OktaComponent implements OnInit {

  constructor(
    public auth: AngularFireAuth,
    public route: Router,
    public commonService: CommonService,
    public auditService : AuditService
  ) {}

  ngOnInit() {
    // this.handleAuthentication();
    this.commonService.removeLocalStorage('ImpersonateUser');
    // Check if the user is already signed in
    this.auth.getRedirectResult().then(result => {
      if (result.user) {
        // User is signed in
        this.commonService.setLocalStorage('oktaUser', result);
        const profile = result?.additionalUserInfo?.profile as { groups?: string[] } | undefined;
        const groups = profile?.groups || [];
        this.commonService.setLocalStorage('OktaRole', groups);
        const delegate = (result.user as any)._delegate;
        this.commonService.setLocalStorage('token', delegate?.accessToken);
        this.commonService.setLocalStorage('isauthenticated', 'true');
        this.saveAuditLog('Logged In to PACT application', 'Login', 'Okta', 'Okta Login', '');
        this.route.navigate(['/app/home']);
      } else {
        // User is not signed in, perform sign-in action
        this.signIn();
      }
    }).catch(error => {
      // Handle errors
      console.error('Error occurred during redirect:', error);
    });
  }

  signIn(): void {
    const provider = new SAMLAuthProvider(environment.firebaseConfig.oktaProvider);
    this.auth.signInWithRedirect(provider);
  }

  saveAuditLog(messageValue: string, useraction: string, screen: string, module: string, transactionId?: string) {
    let para: {
      module: string;
      screen: string;
      message: string;
      useraction: string;
      patientid: string;
      tenantid: string;
      transactionid: string;
    } = {
      module: module,
      screen: screen,
      message: messageValue,
      useraction: useraction,
      patientid: '',
      tenantid: '',
      transactionid: transactionId ? transactionId : '0',
    };
    this.auditService.callApI(para);
  }

  // async handleAuthentication(): Promise<void> {
  //   try {
  //     const result = await this.auth.getRedirectResult();
  //     if (result.user) {
  //       const profile = result?.additionalUserInfo?.profile as { groups?: string[] } | undefined;
  //       const groups = profile?.groups || [];
  //       this.commonService.setLocalStorage('oktaUser', result);
  //       this.commonService.setLocalStorage('OktaRole', groups);
  //       const delegate = (result?.user as any)._delegate;
  //       this.commonService.setLocalStorage('token', delegate?.accessToken);
  //       this.commonService.setLocalStorage('isauthenticated', 'true');
  //       this.route.navigate(['/app/home']);
  //     } else {
  //       this.signIn();
  //     }
  //   } catch (error) {
  //     console.error('Error occurred during authentication:', error);
  //   }
  // }
}