import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
//Kendo
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import firebase from 'firebase/compat/app';
import { ModalModule } from 'ngx-bootstrap/modal';
import { environment } from 'src/environments/environment';
import { LoginComponent } from './alt-views/login/login.component';
import { HttpconfigInterceptor } from './core/services/httpconfig.interceptor';
import { KendoModule } from './kendo.module';
import { OktaComponent } from './sso/okta/okta.component';
import { ToastrModule } from 'ngx-toastr';

const app = firebase.initializeApp(environment.firebaseConfig);
@NgModule({
  declarations: [AppComponent, LoginComponent ,OktaComponent],
  imports: [BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    KendoModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
    }),
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    ModalModule.forRoot()
  ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  { provide: HTTP_INTERCEPTORS, useClass: HttpconfigInterceptor, multi: true }],
  bootstrap: [AppComponent],
})
export class AppModule { }
