import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(private http: HttpClient) { }

  getAllCapabilities(obj: any) {
    return this.http.get<any>(environment.host + "pactreports/reports/compare_capability?srdperiodid=" + obj.srdperiodid + "&srdperiodid_compare=" +  obj.srdperiodid_compare + "&puid=" + obj.puid + "&capabilities=" + obj.capabilities + "&technology=" + obj.technology + "&urgentcare=" + obj.urgentcare + "&collaborative_care=" + obj.collaborative_care);
  }

  isUrgentCare(puid: any) {
    return this.http.get<any>(environment.host + "pact/practiceunit/is_urgentcare_exist?puid=" + puid);
  }

  getAllRequiredCapabilities(obj: any){
    const options = {
      responseType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' as 'json',
    };
    return this.http.get<any>(environment.host + "pactreports/reports/requiredcapibility_excel?periodId=" + obj.periodId + "&poId=" + obj.poId + "&email=" + obj.email, options);
  }

  getAllNominations(obj: any){
    return this.http.get<any>(environment.host + "pactreports/reports/getallnominations?poid=" + obj.poid + "&srdperiodid=" + obj.srdperiodid  + "&email=" + obj.email);
  }
}
