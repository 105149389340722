import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class CapabilityService {
  private componentState: any = { form1: '', form2: '' }; // Change 'any' to your specific type

  constructor(private http: HttpClient) {
  }
  getcapabilitiescount(periodid: any, puid: any, searchtext?: any) {
    return this.http.get<any>(environment.host + "pact/capability/getcapabilitiescount?srdperiodid=" + periodid + "&puid=" + puid + "&searchquestion=" + searchtext);
  }
  getallphysician(obj?: any) {
    return this.http.get<any>(environment.host + "pact/physician/getallphysician?sortby="+ obj.sortby +"&sorttype="+ obj.sorttype +"&pagenumber=" + obj.pagenumber + "&size=" + obj.size + "&puid=" + obj.puid + "&srdperiodid=" + obj.srdperiodid);
  }
  getCapabilitiesReport(obj?: any) {
    const options = {
      responseType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' as 'json',
    };
    return this.http.get<any>(environment.host + "pactreports/reports/capability_excel?periodId=" + obj.periodId + "&poId=" + obj.poId + "&newpus=" + obj.Newpus + "&email=" + obj.email, options);
  }
  pu_summary(puid: any) {
    return this.http.get<any>(environment.host + "pact/practiceunit/pu_summary?puid=" + puid);
  }
  pu_search(obj: any) {
    return this.http.get<any>(environment.host + "pact/practiceunit/pu_search?periodid=" + obj.period + "&poid=" + obj.poid + "&subpoid=" + obj.subpoid + "&practiceunitname=" + obj.practiceunitname + "&pucode=" + obj.pucode + "&lastname=" + obj.lastname + "&sortby=" + obj.sortby + "&inactive=" + obj.inactive + "&newpu=" + obj.isnewpractice + "&pagenumber=" + obj.pagenumber + "&size=" + obj.size + "&email=" + obj.email + "&sorttype=" + obj.sorttype);
  }
  // getAllPURecognition(searchText?: string) {
  //   return this.http.get<any>(environment.host + "pact/recognition/getallrecognitions?designation=" + searchText);
  // }
  getAllPO(id: any, searchText?: string) {
    return this.http.get<any>(environment.host + "pact/physicianorganization/getallphysicianorganization?periodid=" + id + "&poname=" + searchText);
  }
  getAllSubPO(periodid: number, puid?: number, searchText?: string, email?: string) {
    return this.http.get<any>(environment.host + "pact/subpo/getallsubpo?periodid=" + periodid + "&poid=" + puid + "&subponame=" + searchText + "&email=" + email);
  }
  deletePeriod(id: any) {
    return this.http.delete<any>(environment.host + "pact/capabilityperiod/deletebysrdperiodid/" + id);
  }
  updatePeriod(obj: any) {
    return this.http.put<any>(environment.host + "pact/capabilityperiod/updatecapabilityperiod", obj);
  }
  addNewPeriod(obj: any) {
    return this.http.post<any>(environment.host + "pact/capabilityperiod/savecapabilityperiod", obj);
  }
  getallcapabilityperiod(periodname: string) {
    return this.http.get<any>(environment.host + "pact/capabilityperiod/getallcapabilityperiod?periodname=" + periodname);
  }
  getallcapabilitytype() {
    return this.http.get<any>(environment.host + "pact/capabilitytype/getallcapabilitytype");
  }
  getPUsearchreports() {
    // const httpOptions1 = {
    //   headers: new HttpHeaders({ 'Content-Type': 'text/plain', 'Authorization': environment.token })
    // };
    return this.http.get<any>(environment.host + "pact/PactReports/pu_searchReports");
  }
  // getallcapabilityperiod(obj?: any){
  //   return this.http.get<any>(environment.host + "pact/capabilityperiod/getallcapabilityperiod?sortby="+ obj.sortby +"&pagenumber="+ obj.pagenumber +"&size="+ obj.size, httpOptions);
  // }
  getAllCapabilities(periodId: any, puId: any, search?: any, status?: any) {
    return this.http.get<any>(environment.host + "pact/capability/getallquestions?srdperiodid=" + periodId + "&puid=" + puId + "&searchquestion=" + search + "&status=" + status);
  }

  updateCapabilities(obj: any) {
    return this.http.put<any>(environment.host + "pact/capability/updatecapabilitystatus", obj);
  }

  getallPeriods(pucode: string, periodname?: string) {
    return this.http.get<any>(environment.host + "pact/capabilityperiod/getallperiods?pu_code=" + pucode + "&periodname=" + periodname);
  }

  getpobyperiod(periodid: any, email: string, poname?: string) {
    return this.http.get<any>(environment.host + "pact/physicianorganization/getpoidbyperiod?periodid=" + periodid + "&email=" + email + "&poname=" + poname);
  }

  getcapabilityperiodbystatus() {
    return this.http.get<any>(environment.host + "pact/capabilityperiod/getcapabilityperiodbystatus");
  }

  getActivityLog(obj: any) {
    return this.http.get<any>(environment.host + "pact/activitylog/getallactivitylogs?puid=" + obj.puid + "&srdperiodid=" + obj.srdperiodid + "&questionresponseid=" + obj.questionresponseid);
  }

  setComponentState(state1: any, state2: any) {
    this.componentState.form1 = state1;
    this.componentState.form2 = state2;
  }

  getComponentState(): any {
    return this.componentState;
  }

  clearComponentState() {
    this.componentState.form1 = '';
    this.componentState.form2 = '';
  }

  getfiledate() {
    return this.http.get<any>(environment.host + "pact/user/getalignmentdate");
  }
}
