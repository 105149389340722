export class getLogData {
    static getbroweser() {
        let browserName;
        const agent = window.navigator.userAgent.toLowerCase()
        //console.log(agent);  Trigger Build
        if (agent.indexOf('edge') > -1) { //console.log("edge"); 
        browserName = "edge"; }
        else if (agent.indexOf('opr') > -1 && !!(<any>window).opr) { 
            //console.log('opera'); 
            browserName = "Opera"; }
        else if (agent.indexOf('chrome') > -1 && !!(<any>window).chrome) { //console.log('chrome'); 
            browserName = "Chrome" }
        else if (agent.indexOf('trident') > -1 && !!(<any>window).ie) { //console.log('ie'); 
            browserName = "Internet  Explorer" }
        else if (agent.indexOf('firefox') > -1 && !!(<any>window).firefox) { //console.log('firefox'); 
            browserName = "Firefox" }
        if (agent.indexOf('safari') > -1 && !!(<any>window).safari) { //console.log('safari'); 
            browserName = "Safari" }
        return browserName;
    }

}