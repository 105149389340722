export class auditclass {
    "application": string;
    "auditlogcode": string;
    "city": string;
    "clientbrowser": string;
    "clientip": string;
    "continent": string;
    "country": string;
    "countrycode": string;
    "groupid": string;
    "loginuser": string;
    "message": any;
    "module": string;
    "organizationid": string;
    "outcome": string;
    "region": string;
    "screen": string;
    "transactionid": string;
    "useraction": string;
    "userfullname": string;
    "userid": string;
    "impersonateduserid" : string;
    "impersonatedusername" : string;
  }