import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { catchError, map, Observable, switchMap, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/shared/services/common.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { of, from, throwError } from 'rxjs';

@Injectable()
export class HttpconfigInterceptor implements HttpInterceptor {

  constructor(private _commonService: CommonService, public auth: AngularFireAuth) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token = this._commonService.getLocalStorage('token') ? this._commonService.getLocalStorage('token') : '';

    if (token) {
      const decodedToken = JSON.parse(atob(token.split('.')[1])); // Decode the token payload
      const expirationTime = new Date(decodedToken.exp * 1000);
      const currentTime = new Date();
      const timeUntilExpiration = expirationTime.getTime() - currentTime.getTime();
      const refreshTime = Math.max(timeUntilExpiration - (5 * 60 * 1000), 0); // Refresh 5 minutes before expiration

      if (refreshTime === 0) {
        return this.handle401Error(request, next);
      } else {
        request = request.clone({
          setHeaders: {
            Authorization: 'Bearer ' + token,
          },
        });
      }
    }
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          return this.handle401Error(request, next);
        } else {
          return throwError(() => error);
        }
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return from(this.refreshToken()).pipe(
      switchMap((newToken: string | null) => {
        if (newToken) {
          this._commonService.setLocalStorage('token', newToken);
          const authReq = request.clone({
            setHeaders: {
              Authorization: 'Bearer ' + newToken,
            },
          });
          return next.handle(authReq);
        } else {
          return throwError(() => 'Unable to refresh token');
        }
      })
    );
  }


  async refreshToken(): Promise<string | null> {
    const refreshedUser = await this.auth.currentUser;
    if (refreshedUser) {
      try {
        return refreshedUser.getIdToken();
      } catch (error) {
        console.error('Error refreshing token:', error);
        return null;
      }
    }
    return null;
  }
}
