import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FirebaseApp } from '@angular/fire/compat';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { SAMLAuthProvider } from 'firebase/auth';
import { CommonService } from 'src/app/shared/services/common.service';
import firebase from 'firebase/compat/app';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/shared/services/user.service';
import { AuditService } from 'src/app/shared/services/audit.service';

const app = firebase.initializeApp(environment.firebaseConfig);

interface UserProfile {
  Email: string;
  ProviderIDs?: string[];
}

@Component({
  selector: 'app-availity',
  templateUrl: './availity.component.html',
  styleUrls: ['./availity.component.scss'],
})
export class AvailityComponent implements OnInit {

  availityProfile: any = null;
  userDetail: any;
  availityUser: any;


  constructor(public app: FirebaseApp, public auth: AngularFireAuth, public route: Router, public userService: UserService,
    public commonService: CommonService, public auditService: AuditService) { }

  ngOnInit() {
    this.commonService.removeLocalStorage('ImpersonateUser');
    //this.handleAuthentication();
    this.auth.getRedirectResult().then((result: any) => {
      if (result.user) {
        const delegate = (result?.user as any)._delegate;
        this.commonService.setLocalStorage('token', delegate?.accessToken);
        const profile = result?.additionalUserInfo?.profile as UserProfile || { Email: '', ProviderIDs: [] };
        const email = profile.Email;
        console.log('Email', email);
        console.log('Result', result);

        const ProviderIDs = profile.ProviderIDs || [];
        this.userService.getUserExist(email, '').subscribe((res: any) => {
          if (res.userexists == true) {
            this.availityProfile = result?.additionalUserInfo?.profile;
            if (result?.user) {
              this.commonService.setLocalStorage('availityUserId', result?.user?.uid ?? '');
              this.commonService.setLocalStorage('availityUser', result?.additionalUserInfo?.profile);
              this.availityUser = this.commonService.getLocalStorage('availityUser');
              this.saveAuditLog(JSON.stringify(result), 'Troubleshoot', 'Availity', 'getRedirectResult', '');
              // console.log('Availity' , this.availityUser);
              //this.availityUser.po_business_id = this.availityUser.ProviderIDs.split(",")[0]
              if (this.availityUser) {
                this.commonService.setLocalStorage('ImpersonateUser', this.availityUser);
                this.commonService.userData.next(this.availityUser);
              }
              this.saveAuditLog(JSON.stringify(this.availityUser), 'Troubleshoot', 'Availity', 'inIf', '');
              this.saveAuditLog('Logged In to PACT application', 'Login', 'Availity', 'Availity Login', '');
              this.commonService.setLocalStorage('isauthenticated', 'true');
              this.route.navigate(['/app/home']);
            }
          } else {
            this.saveAuditLog(JSON.stringify(this.availityUser), 'Troubleshoot', 'Availity', 'inElse', '');
            this.commonService.isSsoError(true);
            this.route.navigate(['/sso/availity']);
          }
        })
      } else {
        this.signIn();
      }
    })
  }

  signIn(): void {
    const provider = new SAMLAuthProvider(environment.firebaseConfig.availityProvider);
    this.auth.signInWithRedirect(provider);
  }

  saveAuditLog(messageValue: any, useraction: string, screen: string, module: string, transactionId?: string) {
    let para: {
      module: string;
      screen: string;
      message: any;
      useraction: string;
      patientid: string;
      tenantid: string;
      transactionid: string;
    } = {
      module: module,
      screen: screen,
      message: messageValue,
      useraction: useraction,
      patientid: '',
      tenantid: '',
      transactionid: transactionId ? transactionId : '0',
    };
    this.auditService.callApI(para);
  }

  // async handleAuthentication(): Promise<void> {
  //   try {
  //     const result = await this.auth.getRedirectResult();

  //     if (result.user) {
  //       const delegate = (result?.user as any)._delegate;
  //       this.commonService.setLocalStorage('token', delegate?.accessToken);
  //       const profile = result?.additionalUserInfo?.profile as UserProfile || { email: '', ProviderIDs: [] };
  //       const email = profile.email;
  //       const ProviderIDs = profile.ProviderIDs || [];
  //       this.userService.getUserExist(email, ProviderIDs).subscribe((res: any)=>{
  //         if(res.userexists == false ){
  //           this.availityProfile = result?.additionalUserInfo?.profile;
  //           if(result.user){
  //             this.commonService.setLocalStorage('availityUser' , result?.additionalUserInfo?.profile);
  //             this.availityUser = this.commonService.getLocalStorage('availityUser');
  //             // console.log('Availity' , this.availityUser);
  //             this.availityUser.po_business_id = this.availityUser.ProviderIDs;
  //             if(this.availityUser){
  //               this.commonService.setLocalStorage('ImpersonateUser', this.availityUser);
  //               this.commonService.userData.next(this.availityUser);
  //             }
  //             this.commonService.setLocalStorage('isauthenticated','true');
  //             this.route.navigate(['/app/home']);
  //           }
  //         }else{
  //           this.route.navigate(['/sso/availity']);
  //         }
  //      })
  //     } else {
  //       this.signIn();
  //     }
  //   } catch (error) {
  //     console.error('Error occurred during authentication:', error);
  //   }
  // }

}