import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GridModule } from '@progress/kendo-angular-grid';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { LabelModule } from '@progress/kendo-angular-label';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ComboBoxModule } from '@progress/kendo-angular-dropdowns';
import { DialogsModule } from "@progress/kendo-angular-dialog";
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
@NgModule({
  declarations: [],
  exports:[
    GridModule,
    DateInputsModule,
    ButtonModule,
    LabelModule,
    InputsModule,
    DropDownsModule,
    ComboBoxModule,
    DialogsModule,
    ButtonsModule,
    TooltipsModule,
    ProgressBarModule,
    LayoutModule
  ],
  imports: [
    CommonModule,
  ]
})
export class KendoModule { }
