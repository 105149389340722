<div class="logout_dialog bg-gray05 display-f align-center justify-center w-100">

  <div class="logout_wrap">
    <div class="column align-center justify-center">
      <!-- <div class="logo mb-2 w-75">
        <img src="../../../../../assets/images/Value-Partnerships-Logo.png"/>
      </div> -->
      <div class="mb-4 divider_wrap">
        <div class="check_icon_wrap">
          <img src="../../../../../assets/images/check.png"/>
        </div>
      </div>
      <ion-text class="font-header fw-500 text-center" color="gray100">You are successfully Logged Out</ion-text>
      <ion-text class="font-text text-center mt-1" color="gray80">Thank you for using PACT Tool</ion-text>
      <!-- <div class="mt-3">
        <button kendoButton fillMode="solid" class="btn-highlight-solid font-size-text w-100" routerLink="/login">Login</button>
      </div> -->
    </div>
  </div>
      
  
</div>