import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router'; // Import ActivatedRoute from '@angular/router' module
import { AuditService } from 'src/app/shared/services/audit.service';
import { CapabilityService } from 'src/app/shared/services/capability.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { ReportsService } from 'src/app/shared/services/reports.service';

@Component({
  selector: 'app-report-download',
  templateUrl: './report-download.component.html',
  styleUrls: ['./report-download.component.scss'],
  host: {
    class: 'justify-inherit'
  }
})
export class ReportDownloadComponent implements OnInit {
  isDesktop: boolean;
  downloadComplete: boolean = false;
  serviceResponse: any;
  apiRequestDateTime: any;
  apiCompletionDateTime: any;
  inputData: any;
  breakpoint: any = window.matchMedia('(min-width: 768px)');
  constructor(private reportService: ReportsService, 
              private commonservice: CommonService,
              private auditService: AuditService,
              private capabilityService: CapabilityService) { 
    this.isDesktop = !!this.breakpoint.matches;
  } // Declare reportService as a property

  ngOnInit() {
    this.inputData = this.commonservice.getLocalStorage('reportObj')
    this.downloadComplete = false;
    this.apiRequestDateTime = new Date();
    if (this.inputData.report === 'Required Capabilities') {
      this.reportService.getAllRequiredCapabilities(this.inputData).subscribe((res: any) => {
        // this.commonservice.isDownloading(false);
        this.apiCompletionDateTime = new Date();
        this.downloadComplete = true;
        this.serviceResponse = res;
      });
    } else if (this.inputData.report === 'Excel Report') {
      this.capabilityService.getCapabilitiesReport(this.inputData).subscribe((res: any) => {
        // this.commonservice.isDownloading(false);
        this.apiCompletionDateTime = new Date();
        this.downloadComplete = true;
        this.serviceResponse = res;
      })
    }
  }

  requiredReportDownload() {
    const linkSource =
      'data:application/octet-stream;base64,' + this.serviceResponse;
    let fileLink = document.createElement('a');
    fileLink.href = linkSource;
    const fileName = "RequiredCapabilities_" + `${this.inputData.period}.xlsx`;
    fileLink.download = fileName;
    fileLink.click();
    this.saveAuditLog('Downloaded Required Capabilities report', 'Download', 'Practice unit list', 'Required Capabilities Report', this.serviceResponse.poid);
  }

  excelReportDownload() {
    const linkSource =
      'data:application/octet-stream;base64,' + this.serviceResponse;
    let fileLink = document.createElement('a');
    fileLink.href = linkSource;
    const fileName = "Capabilities_" + `${this.inputData.period}.xlsx`;
    fileLink.download = fileName;
    fileLink.click();
    this.saveAuditLog('Downloaded Excel Report', 'Download', 'Practice unit list', 'Excel Report', this.serviceResponse.poid);
  }

  saveAuditLog(messageValue: string, useraction: string, screen: string, module: string, transactionId?: string) {
    let para: {
      module: string;
      screen: string;
      message: string;
      useraction: string;
      patientid: string;
      tenantid: string;
      transactionid: string;
    } = {
      module: module,
      screen: screen,
      message: messageValue,
      useraction: useraction,
      patientid: '',
      tenantid: '',
      transactionid: transactionId ? transactionId : '0',
    };
    this.auditService.callApI(para);
  }
}
