import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { TextBoxComponent } from '@progress/kendo-angular-inputs';

import { Router } from '@angular/router';
import { LoginService } from 'src/app/shared/services/login.service';
import { AlertController } from '@ionic/angular';
import { CommonService } from 'src/app/shared/services/common.service';
import { AuditService } from 'src/app/shared/services/audit.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  @ViewChild('passwordInput')
  private passwordInput!: TextBoxComponent;

  usernameControl = new FormControl('', Validators.required);
  passwordControl = new FormControl('', Validators.required);
  loginForm: FormGroup = new FormGroup({
    username: new FormControl(),
    password: new FormControl(),
  });
  invalid: boolean = false;
  public errorMessage: string = 'Please check your credentials and try again.';
  constructor(
    public route: Router,
    private _loginService: LoginService,
    private alertController: AlertController,
    private _commonService:CommonService,
    public auditService : AuditService
  ) {
    this.loginForm = new FormGroup({
      username: this.usernameControl,
      password: this.passwordControl,
    });
  }

  ngOnInit() {
    this.invalid = false;
  }
  public ngAfterViewInit() {
    this.passwordInput.input.nativeElement.type = 'password';
  }
 async login() {
    const username = this.loginForm.get('username')?.value;
    const password = this.loginForm.get('password')?.value;
    this._loginService.doLogin({ username: username, password: password }).then(
      (token ) => {
        if(token){
          this._commonService.setLocalStorage('token',token);
          //console.log('LoginToken' , this._commonService.getLocalStorage('token'));
          this._commonService.setLocalStorage('isauthenticated','true');
          this.saveAuditLog('Logged In to PACT application', 'Login', 'Login', 'Firebase Login', '');
          this.route.navigate(['/app/home']);
          this.invalid = false;
        }else{
          this.invalid = true;
        }
      },
      (err) => {
        // console.log('Error Code: ' + err.code);
        this.invalid = true;
       /* if (err.code === 'auth/invalid-email') {
          this.errorMessage = 'The email address is badly formatted.';
          this.alertLoginFailed(this.errorMessage);
        } else if (err.code == 'auth/too-many-requests') {
          this.errorMessage =
            'Too many unsuccessful login attempts. For security reasons your account is locked temporarily. Please try again later.';
          this.alertLoginFailed(this.errorMessage);
        } else if (err.code == 'auth/wrong-password') {
          this.errorMessage = 'Wrong password';
          this.alertLoginFailed(this.errorMessage);
        } else if (err.code == 'auth/user-not-found') {
          this.errorMessage =
            // 'The email is invalid';
            'The email not found';
          this.alertLoginFailed(this.errorMessage);
        } else {
          this.errorMessage = err.code;
          this.alertLoginFailed(this.errorMessage);
        }*/
      }
    );
  }
  async alertLoginFailed(msg: String) {
    const alert = await this.alertController.create({
      header: 'Login failed',
      message: msg.toString(),
      buttons: ['Ok'],
    });
    await alert.present();
  }
  
  saveAuditLog(messageValue: string, useraction: string, screen: string, module: string, transactionId?: string) {
    let para: {
      module: string;
      screen: string;
      message: string;
      useraction: string;
      patientid: string;
      tenantid: string;
      transactionid: string;
    } = {
      module: module,
      screen: screen,
      message: messageValue,
      useraction: useraction,
      patientid: '',
      tenantid: '',
      transactionid: transactionId ? transactionId : '0',
    };
    this.auditService.callApI(para);
  }
}
