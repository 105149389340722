<div class="login_main">
  <ion-toolbar>
    <div class="display-f  bg-white header-wrapper">
      <div class="logo-wrapper" slot="start">
        <ion-img class="logo-img" src="../../../../assets/images/Value-Partnerships-Logo.png"></ion-img>
      </div>
    </div>
  </ion-toolbar>
  <div class="login_container p-3">
    <div class="logo_container display-f align-center flex-direction-column">
      <div class="logo_main">
        <img src="../../../../assets/svg/logo2.svg"/>
      </div>
      <div class="column  ml-2">
        <ion-text class="fw-400 pro_name" color="white">Self Assessment Tool</ion-text>
        <!-- <ion-text class="sub-title fw-300"  color="white">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla. </ion-text> -->
      </div>
    </div>
    <div class="login-container bg-white p-5">
      <form [formGroup]="loginForm">
        <div class="text-center mb-2">
          <ion-text class="font-xl fw-500 " color="gray100">Login</ion-text>
        </div>
        <div class="form-group">
          <div class="column mb-2">
            <ion-text color="gray80" class="font-text text-upper mb-half">Username</ion-text>
            <kendo-formfield>
              <kendo-textbox class="form-text-input" color="gray100" placeholder="Type your username*"
                formControlName="username" required></kendo-textbox>
              <kendo-formerror>Required</kendo-formerror>
            </kendo-formfield>
          </div>        
        </div>
        <div class="form-group">
          <div class="column mb-2">
            <ion-text color="gray80" class="font-text text-upper mb-half">Password</ion-text>
            <kendo-formfield>
            <kendo-textbox
              class="form-text-input"
              formControlName="password"
              #passwordInput="kendoTextBox"
              placeholder="Type your Password*"
              required
              >
            </kendo-textbox>
            <kendo-formerror>Required</kendo-formerror>
          </kendo-formfield>
          </div>
          <!-- <input type="password" formControlName="password"> -->
        </div>
        <div class="text-center  mb-1">
          <button  kendoButton  fillMode="solid" class="btn-highlight-solid font-size-text" (click)="login()">Login</button>
          <ion-text color="error" class="font-d display-b pt-1" *ngIf="invalid">Oops! Invalid Credentials.</ion-text>
        </div>
          
      </form> 
    </div>
  </div>
  
</div>
